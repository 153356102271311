import Cart from "./pages/Cart";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Product from "./pages/Product";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import SharedLayout from "./pages/SharedLayout";
import ScrollToTop from "./components/ScrollToTop";
import Checkout from "./pages/Checkout";
import AddProduct from "./pages/AddProduct";

const App = () => {
  return(
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={<SharedLayout/>}>
        <Route index element={<Home/>}/>
        <Route path='/product/:productId' element={<Product/>}/>
        <Route path='/products' element={<ProductList/>}/>
        <Route path='/cart/:productId/:quantity' element={<Cart/>}/>
        <Route path='/cart' element={<Cart/>}/>
        <Route path='/checkout' element={<Checkout/>}/>
        <Route path='/addProduct' element={<AddProduct/>}/>
        </Route>
      </Routes>
      <Routes>
      <Route path='/login' element={<Login/>}/>
      <Route path='/register' element={<Register/>}/>
      </Routes>
    </Router>
    
  ) 
};

export default App;