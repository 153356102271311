import { Facebook, Instagram, MailOutline, Phone, Room, Twitter } from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'
import {mobile} from '../Responsive'
import { Link } from 'react-router-dom'

const Container= styled.div`
display: flex;
${mobile({flexDirection: "column"})}`

const Left= styled.div`
flex:1;
display: flex;
flex-direction: column;
margin-left: 10px;`

const Logo=styled.h1``

const Desc=styled.p`
margin: 20px 0px;`

const SocialContainer=styled.div`
display:flex;
flex-direction: row;`

const SocialIcon= styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
color: white;
background-color: #${props=>props.color};
display: flex;
align-items: center;
justify-content: center;
margin-right: 20px;`

const SocialLink= styled.a`
    text-decoration: none;
    color: white;
`

const Center= styled.div`
flex:1;
padding: 20px;
${mobile({display: "none"})}`

const Title=styled.h3`
margin-bottom: 30px;`

const List= styled.ul`
margin: 0;
padding:0;
list-style: none;
display: flex;
flex-wrap: wrap;`

const ListItem= styled.li`
width: 50%;
margin-bottom: 10px;`

const Right= styled.div`
flex:1;
padding: 20px;`

const ContactItem=styled.div`
margin-bottom: 20px;
display: flex;
align-items: center;`

const Footer = () => {
  return (
    <Container>
        <Left>
            <Logo>Wow Kids</Logo>
            <Desc>For high quality wood items</Desc>
            <SocialContainer>
                <SocialIcon color="3B5999">
                <SocialLink href="https://web.facebook.com/profile.php?id=100067016946548/" target="_blank" rel="noreferrer">
                    <Facebook/>
                    </SocialLink>
                </SocialIcon>
                <SocialIcon color="E4405F">
                    <SocialLink href="https://www.instagram.com/wow_kraft/" target="_blank" rel="noreferrer">
                    <Instagram/>
                    </SocialLink>
                </SocialIcon>
                <SocialIcon color="55ACEE">
                    <SocialLink href="https://twitter.com/KraftWow" target="_blank" rel="noreferrer">
                    <Twitter/>
                    </SocialLink>
                </SocialIcon>
            </SocialContainer>
        </Left>
        <Center>
            <Title>Useful Links</Title>
            <List>
            <ListItem>Home</ListItem>
            <ListItem>Cart</ListItem>
            <ListItem>Toys</ListItem>
            <ListItem>Planters</ListItem>
            </List>
        </Center>
        <Right>
            <Title>Contacts</Title>
            <ContactItem><Room style={{marginRight: "20px"}}/> Ruaka, Nairobi</ContactItem>
            <ContactItem><Phone style={{marginRight: "20px"}}/>+254703924564</ContactItem>
            <ContactItem><MailOutline style={{marginRight: "20px"}}/>wowkraft254@gmail.com</ContactItem>
        </Right>
    </Container>
  )
}

export default Footer