import { Send } from '@material-ui/icons'
import styled from 'styled-components'
import {mobile} from '../Responsive'


const Container=styled.div`
background-color: #fcf5f5;
height: 60vh;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;`

const Title= styled.h1`
font-size: 70px;
margin-bottom: 20px;

`

const Description=styled.div`
font-size: 24px;
font-weight: 300;
margin-bottom: 20px;
${mobile({textAlign:"center"})}`

const Input= styled.input`
border: none;
flex:8;
padding-left: 20px;`

const InputContainer= styled.div`
width: 50%;
height: 40px;
background-color: #fff;
display: flex;
align-items: center;
justify-content: space-between;
border: 1px solid gray;
${mobile({width:"80%"})}
`

const Button=styled.button`
flex:1;
border: none;
background-color: teal;
color: white;
cursor: pointer;`


const Newsletter = () => {
  return (
    <Container>
        <Title>News Letter</Title>
        <Description>Get timely updates on all our products and offers.</Description>
        <InputContainer>
        <Input placeholder="Your Email"/>
        <Button>
            <Send/>
        </Button>
        </InputContainer>
        
    </Container>
  )
}

export default Newsletter