import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import Products from '../components/Products'
import { mobile } from '../Responsive'
import useFetch from '../useFetch'

const Container=styled.div``

const Title= styled.h1`
margin: 20px;`

const FilterContainer= styled.div`
display: flex;
justify-content: space-between;`

const Filter= styled.div`
margin: 20px;
${mobile({margin: "0px 20px",display: "flex", flexDirection:"column"})}`

const FilterText= styled.span`
    font-size: 20px; 
    font-weight: 600; 
    margin-right: 20px;
    ${mobile({marginRight: "0px"})}
`
const Select=styled.select`
padding: 10px;
margin-right: 20px;
${mobile({margin: "10px 0px"})}
`

const Option=styled.option``

const ProductList = () => {
    const [url,setUrl]=useState("products/getproducts")
    const {data: categories, loading:loadingcategories, error: getcategoryerror}= useFetch("setup/getCategories")
    const {data: agegroups, loading:loadingagegroups, error: getagegroupserror}= useFetch("setup/getagegroups")
    

    const [selectedOption, setSelectedOption] = useState("all");
    const [selectedCategory, setSelectedCategory] = useState("all");
    useEffect(()=>{
        if (selectedOption==="all" && selectedCategory==="all"){
            setUrl(`Products/GetProducts`)
        }
        else if (selectedOption!=="all" && selectedCategory==="all"){
            setUrl(`Products/GetProductByAgeGroup/${selectedOption}`)
        }
        else if(selectedOption!=="all" && selectedCategory!=="all"){
            setUrl(`Products/GetProductByAgeGroupAndCategory/${selectedOption}/${selectedCategory}`)
        }
        else if (selectedOption==="all" && selectedCategory !=="all"){
            setUrl(`Products/GetProductByCategory/${selectedCategory}`)
        }
            {console.log(url)}
        },[selectedOption,selectedCategory]);

  return (
    <Container>
        <Title>Toys</Title>
        <FilterContainer>
            <Filter><FilterText>Filter Products</FilterText>
                <Select value={selectedCategory}
        onChange={e => setSelectedCategory(e.target.value)}>
                    <Option value="all" selected> Category</Option>
                    {categories?.map((item)=>(
                        <Option value={item.categoryId}>{item.name}</Option>
                    ))}
                    
                </Select>
                <Select value={selectedOption}
        onChange={e => setSelectedOption(e.target.value)}>
                    <Option value="all" selected>Age Group</Option>
                    {agegroups?.map((item)=>(
                        <Option value={item.ageGroupId}>{item.name}</Option>
                    ))}
                </Select>
            </Filter>
            <Filter><FilterText>Sort Products</FilterText>
            <Select>
                    <Option  selected> Newest</Option>
                    <Option>Price (asc)</Option>
                    <Option>Price (desc)</Option>
                </Select>
            </Filter>
        </FilterContainer>
        <Products url={url}/>
    </Container>
  )
}

export default ProductList