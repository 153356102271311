import React from 'react'
import styled from 'styled-components'
import { popularProducts } from '../data'
import Product from './Product'
import { useEffect, useState } from 'react'
import useFetch from '../useFetch'
import { HourglassEmptyOutlined, LocalActivityOutlined } from '@material-ui/icons'
import {v4 as uuidv4} from 'uuid'
import axios from 'axios'

const Container= styled.div`
display:flex;
flex-wrap: wrap;
`
const ErrorContainer= styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: 5px 5px 5px 5px teal;
margin: 20px;
`

const Header= styled.div`
display: flex;
width: 100%;
margin: 0px 20px;
justify-content: center;
align-items: center;`

const Line=styled.div`
  border-top: 1px solid black;
  flex:2;
`

const Title= styled.h1`
flex: 2;
padding-left: 30px;
`

const Image=styled.img`
width: 75%`

const Info= styled.span`
  width: 70%;
  text-align: center;
`

const Products = ({url}) => {  
  
  const [visitorId, setVisitorId]=useState()
  console.log("URL is" + url)
  const {data, loading, error}= useFetch(url)
    //get customerId
console.log(data)
    useEffect(() => {
      const visitor = localStorage.getItem('visitor');
      if (visitor) {
        setVisitorId(visitor);
      }
      else{
        const newvisitor=uuidv4()
        localStorage.setItem('visitor',newvisitor );
        setVisitorId(newvisitor)
      }
    }, []);
    useEffect(()=>{

      axios.post('https://wowkids.ddns.net/wowkids/Customer/addCustomer', {
        Uuid: visitorId
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    },[visitorId])

  if (loading) return(
    <Container>
      <HourglassEmptyOutlined/>
      <Image src="assets/imgs/loader.jpg"/>
    </Container>
  )
  //

  if(error) return(
    <ErrorContainer>
    <Title>It's not you. It's us. </Title>
      <Info>Okay, here is the thing. Our products are stored in a databse. And we pay for that database. Per hour. So, at times, for no reason at all, we be convinced that no one is testing and shut diown the database. It saves us like $0.0034 per hour. It's something. We promise we will be live soon. Live as in Live for good.</Info>
    </ErrorContainer>
  ) 

  return (
    <Container>
    <Header>
    <Line></Line>
    <Title>FEATURED PRODUCTS</Title>
    <Line></Line>
    </Header>
      {console.log(`data is ${data}`)}
        {data?.map((item)=>(
          
            <Product item={item} key={item.productId}/>
        ))}
    </Container>
  )
}

export default Products