import { Badge } from '@material-ui/core'
import { AddToQueue, Search, ShopOutlined, ShoppingCartOutlined, VerifiedUser } from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'
import { useState } from 'react'
import {mobile} from '../Responsive'
import {BrowserRouter, Routes, Route, Link, NavLink} from 'react-router-dom'

const Container=styled.div`
height: 60px;
${mobile({height: "100%"})}
`
const Wrapper=styled.div`
padding: 10px;
display:flex;
justify-content:space-between;
${mobile({padding: "10px 0px"})}
`

const Languages=styled.span`
cursor:pointer;
${mobile({display: "none"})}
`
const SearchContainer=styled.div `
border: 1px solid lightgray ;
display:flex;
align-items:center;
margin-left:25px;
padding: 10px;
`
const Input=styled.input`
border: none;
${mobile({width: "50px"})}
`
const Logo=styled(Link)`
font-weight: bold;
text-decoration: none;
color: black;
${mobile({fontSize: "20px"})}
`
const Image=styled.img`
  width: 40px;
`

const Left=styled.div`
flex:1;
display:flex;
align-items:center;
`
const Center=styled.div`
flex:1;
display:flex;
align-items:center;
justify-content: center;
`
const Right=styled.div`
flex:1;
display:flex;
align-items: center;
justify-content: flex-end;
${mobile({flex:2})}
`
const MenuItem= styled(NavLink)`
font-size:14px;
cursor:pointer;
margin-left:25px;
text-decoration: none;
color: black;
${mobile({fontSize: "12px", marginLeft:"10px", display: "none"})}

`

const OpenLinksButton=styled.button`
width: 70px;
height: 50px;
background: none;
border: none;
color: teal;
font-size: 30px;
cursor: pointer;
display: none;
${mobile({display: "block"})}
`
const MobileWrapper=styled.div`
display: none;
${mobile({display:"flex",flexDirection: "column",alignItems: "start", zIndex: "1000", position: "absolute", backgroundColor: "white", height: "100vh", width: "85%"})}
`
const MobileMenuItem= styled(NavLink)`
font-size:20px;
font-weight: 700;
cursor:pointer;
margin:15px 25px;
text-decoration: none;
color: black;

`
const Navbar = ({cartItems}) => {
   const [mobileNavbar, setMobileNavbar]= useState(false)
  return (
    <Container MobileNavbar={mobileNavbar}>
        <Wrapper>
        <Left>
        <Logo to="/">
          <Image src="./assets/imgs/wowkidslogo.jpg" alt="Wow Kids."></Image>
        </Logo>
        </Left>
        
        <Right>
            <MenuItem to ="/products">SHOP NOW</MenuItem>
            <MenuItem to ="/register">REGISTER</MenuItem>
            <MenuItem to ="/login">SIGN IN</MenuItem>
            <OpenLinksButton onClick={()=>{setMobileNavbar((curr)=>!curr)}}>{!mobileNavbar? '☰':  'X' }</OpenLinksButton>
            <MobileMenuItem>
            <Badge badgeContent={cartItems} color="primary">
            <Link to ="/cart"><ShoppingCartOutlined/></Link> 
            </Badge>
            </MobileMenuItem>
        </Right>
        </Wrapper>
        {mobileNavbar && (
          <MobileWrapper>
        <MobileMenuItem to ="/products" onClick={()=>{setMobileNavbar((curr)=>!curr)}}>
          <ShopOutlined/>
          SHOP NOW
        </MobileMenuItem>
        <MobileMenuItem to ="/register" onClick={()=>{setMobileNavbar((curr)=>!curr)}}> <AddToQueue/> REGISTER</MobileMenuItem>
            <MobileMenuItem to ="/login"> <VerifiedUser/> SIGN IN</MobileMenuItem>
            
        </MobileWrapper>
        )}
        
        
    </Container>
  )
}

export default Navbar