import React, { useEffect, useState } from 'react'
import axios from 'axios'

const client = axios.create({
    baseURL: "https://wowkids.ddns.net/wowkids/" 
  });
const useFetch = (url, requestoptions) => {
    const[data, setData]= useState(null)
    const[loading, setLoading]= useState(true)
    const[error, setError]= useState(null)
    useEffect(()=>{
        setLoading(true);
        client.get(url, {
            body: requestoptions
         }).then((response)=>{
            setError(null) //to reset after reload
           setData(response.data)
        }).catch((err)=>{
            setError(err)
        }).finally(()=>{
            setLoading(false)
        })
    },[url]);

    return{data, loading, error}
}

    

export default useFetch