import React from 'react'
import styled from 'styled-components'
import {mobile} from '../Responsive'

const Container= styled.div`
width:100%;
height: 100vh;
background: linear-gradient(rgba(255,255,255,0.5),rgba(255,255,255,0.5) ), url("assets/imgs/image2.jpeg");
display: flex;
align-items: center;
justify-content: center;
`
const Wrapper= styled.div`
width: 40%;
padding: 20px;
background-color: white;
${mobile({width: "75%"})}
`
const Title= styled.h1`
font-size: 24px;
font-weight: 300;`

const Form= styled.form`
display: flex;
flex-wrap:wrap;

`

const Button= styled.button`
width: 40%;
border: none;
padding: 15px 20px;
background-color: teal;
color: white;
cursor: pointer;
 `

const Agreement= styled.span`
font-size: 20px;
margin: 20px 0px;`

const Input= styled.input`
flex:1;
min-width: 40%;
padding: 10px;
margin: 10px 20px 0px 0px;`



const Register = () => {
  return (
    <Container>
        <Wrapper>
            <Title>Create an Account</Title>
            <Form>
                <Input placeholder="name"/>
                <Input placeholder="Last Name"/>
                <Input placeholder="Username"/>
                <Input placeholder="email"/>
                <Input placeholder="password"/>
                <Input placeholder="confirm password"/>
                <Agreement>
                    By creating an account, I consent to the processing of my personal data in accordance with the <b>PRIVACY POLICY</b>
                </Agreement>
                <Button>CREATE</Button>
            </Form>
        </Wrapper>
    </Container>
  )
}

export default Register