import React from 'react'
import styled from 'styled-components'
import { Add, ContactlessOutlined, Remove } from '@material-ui/icons'
import { mobile } from '../Responsive'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import useFetch from '../useFetch'
import { createContext } from 'react'

const CartContext=createContext(null)

const Container =styled.div``

const Wrapper=styled.div`
padding: 20px;
${mobile({padding:"10px"})}
`

const Title= styled.h1`
font-weight: 300;
text-align: center;`

const Top= styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 20px;`

const TopButton= styled(Link)`
    padding: 10px;
    font-weight: 600;
    border: ${props=>props.type==="filled" && "none"};
    background-color: ${props=>props.type==="filled" ? "black": "transparent"};
    color: ${props=>props.type==="filled" && "white"};
`

const TopTexts= styled.div`
${mobile({display:"none"})}`

const TopText=styled.span`
text-decoration:underline;
cursor: pointer;
margin: 0px 10px;
`

const Bottom= styled.div`
    display: flex;
    justify-content: space-between;
    margin:1px 5%;
    border: 1px solid lightgray;
    padding: 5px;
    ${mobile({flexDirection:"column"})}`

const Info = styled.div`
flex:3;
`


const Product= styled.div`
display: flex;
justify-content: space-between;
margin: 10px 0px;
${mobile({flexDirection:"column"})}
`

const ProductDetails= styled.div`
flex: 2;
display: flex;`

const Image= styled.img`
width: 50%;
height: 100%;`

const Details= styled.div`
padding: 20px;
display: flex;
flex-direction: column;
justify-content: space-around;`

const ProductName= styled.span``

const ProductId= styled.span``

const ProductColor= styled.div`
height: 20px;
width: 20px;
border-radius: 15px;
background-color: ${props=>props.color}`

const ProductSize= styled.span``

const PriceDetails= styled.span`
flex:1;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;`

const ProductAmountContainer= styled.div`
display: flex;
align-items: center;
margin-bottom: 20px;
cursor: pointer;
`

const ProductAmount= styled.div`
font-size: 24px;
margin: 5px;
${mobile({margin:"5px 15px"})}
`

const ProductPrice= styled.div`
font-size: 30px;
font-weight: 300;
${mobile({marginBottom:"20px"})}
`


const Hr=styled.hr`
background-color:#eee;
border: none;
height: 1px;
margin: 10px 0px;
`

const Summary=styled.div`
flex:1;
border: 0.5px solid lightgray;
border-radius: 10px;
padding: 20px;
height:50vh;`



const SummaryTitle=styled.h1`
font-weight: 200;`

const SummaryItem=styled.div`
margin: 30px 0px;
display: flex;
justify-content: space-between;
font-size: ${props=>props.type==="total" && "25px"};
font-weight: ${props=>props.type==="total" && "600"};`

const SummaryItemText=styled.span`

`

const SummaryItemPrice=styled.span``

const Button= styled.button`
width: 100%;
padding:10px;
background-color: black;
color: white;
font-weight: 600;
`
const MenuItem= styled(Link)`
font-size:14px;
cursor:pointer;
margin-left:25px;
text-decoration: none;
color: black;
${mobile({fontSize: "12px", marginLeft:"10px", display: "none"})}

`



const Cart = () => {
    const [cartItem, setCartItem]=useState()
    //const [productId, setProductId]=useState()
    const {productId, quantity}=useParams()
    console.log(quantity)
 
    useEffect(()=>{
    const baseurl="https://wowkids.ddns.net/wowkids/"
     axios.post(`${baseurl}Trans/addToCart`, {
        customer: localStorage.getItem('visitor'),
        ProductId:Number(productId),
        Cost:1,
        Quantity:Number(quantity),
        Status:"NEW",
        Active: true
    })
    .then(function (response) {
    console.log(response);
    })
    .catch(function (error) {
    console.log(error.response);
    console.log(localStorage.getItem('visitor'))
    });
    },[cartItem]);

    const {data, loading, error}= useFetch("Trans/GetCustomerCart/"+localStorage.getItem('visitor'))





    if (loading) return(
    <Container>
      <Image src="assets/imgs/loader.jpg"/>
    </Container>
  )
  //

  if(error) return(
    <Container>An error Occured {error.message}</Container>
  ) 
if(data.customer=="Empty")


return(<p>empty cart</p>)
else
    var totalItems=data?data[0].totalCost:0;
    var phone=data[0].phoneNumber?data[0].phoneNumber:"254"
  return (
    
    <Container>
        <Wrapper>
            <Title>YOUR CART</Title>
            <Top>
                <TopButton type="filled" to="/products">CONTINUE SHOPPING</TopButton>
                <TopTexts>
                    <TopText>Shopping Cart(2)</TopText>
                    <TopText>Your Wishlist</TopText>
                </TopTexts>
                <TopButton type="filled" to={`/checkout`} state={{ cart: data, phone:phone, amount:totalItems }}>CHECK OUT NOW</TopButton>
            </Top>
            <Bottom>
                <Info>
                       {data?.map((item)=> (
                        
                        <Product>
                        <Hr/>
                            <ProductDetails>
                            <Image src={item.product.imgUrl}/>
                            <Details>
                                <ProductName><b>Product: </b>{item.product.name}</ProductName>
                                <ProductId><b>ID: </b>{item.product.productId}</ProductId>
                                <ProductColor color="black"/>
                                <ProductSize><b>Size:</b> Large</ProductSize>
                                
                            </Details>
                            </ProductDetails>
                            <PriceDetails>
                            <ProductAmountContainer>
                            <Remove/>
                                <ProductAmount>{item.quantity}</ProductAmount>
                            <Add />
                            </ProductAmountContainer>
                            <ProductPrice>KES {item.product.cost}</ProductPrice>
                            <Hr/>
                        </PriceDetails>
                    </Product>
                        ))}
                        
                   
                    
                </Info>
                <Summary>
                    <SummaryTitle>ORDER SUMMARY</SummaryTitle>
                    <SummaryItem>
                        <SummaryItemText>Subtotal</SummaryItemText>
                        <SummaryItemPrice>KES {data[0].totalCost}</SummaryItemPrice>
                    </SummaryItem>
                    <SummaryItem>
                        <SummaryItemText>Estimated Shipping</SummaryItemText>
                        <SummaryItemPrice>KES 0.00</SummaryItemPrice>
                    </SummaryItem>
                    <SummaryItem>
                        <SummaryItemText>Shipping Discount</SummaryItemText>
                        <SummaryItemPrice>KES 0.00</SummaryItemPrice>
                    </SummaryItem>
                    <SummaryItem type="total">
                        <SummaryItemText >Total</SummaryItemText>
                        <SummaryItemPrice>KES {data[0].totalCost}</SummaryItemPrice>
                    </SummaryItem>
                    <Button>CHECKOUT NOW</Button>
                </Summary>
            </Bottom>
        </Wrapper>
    </Container>
  )
}

export default Cart 