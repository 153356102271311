import { ShoppingCartOutlined, SearchOutlined,FavoriteBorderOutlined  } from '@material-ui/icons'
import React, { useState } from 'react'
import styled from 'styled-components'
import { mobile } from '../Responsive'
import { useNavigate, useParams } from 'react-router-dom'

const Info= styled.div`
opacity: 0;
width: 100%;
height: 100%;
top: 0;
bottom: 0;
z-index:3;
display: flex;
align-items: center;
justify-content: center;
transition: all 1s ease;
${mobile({opacity:"1"})}`


const Container= styled.div`
  flex: 1;
margin: 5px;
min-width: 280px;
min-height:100px;
height: 350px;
display: flex;
align-items: center;
justify-content: center;
position: relative;
&:hover ${Info}{
opacity: 1;
}  
`
const TopContainer=styled.div`
cursor: pointer;
&:hover{
    background-color: #e9f5f5;
    transform: scale(1.1);
}
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
`

const Circle= styled.div`
width: 17%;
height: 17%;
border-radius: 50%;
background-color: white;
position: absolute;`

const Image= styled.img`
height: 75%;
min-height:75%;
width: 85%;
max-width: 400px;

z-index:2;`

const Icon= styled.div`
width: 40px;
height: 40px;
cursor: pointer;
border-radius: 50%;
background-color: white;
display:flex;
align-items: center;
justify-content: center;
margin: 10px;
transition: all 0.5s ease;

&:hover{
    background-color: #e9f5f5;
    transform: scale(1.1);
}`

const Details=styled.div`
text-align: center;
`
const Title= styled.div``

const Price= styled.div``

const Product = ({item}) => {
    const navigate=useNavigate();
    console.log(item)
    const AddToCart=(itemId)=>{
        navigate("/product/"+ itemId);
    }
  return (
    <Container>
        <TopContainer onClick={()=>AddToCart(item.productId)}>
        <Image src={item.imgUrl}/>        
        <Details>
        <Title>{item.name}</Title>
        <Price>KES {item.cost}</Price>
        </Details>
        </TopContainer>
    </Container>
  )
}

export default Product