import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Container= styled.div`
flex: 1;
margin: 3px;
height: 70vh;
min-height: 20vh;
position: relative;`

const Image= styled.img`
width:100%;
height: 100%;
object-fit: cover;
opacity:.3;
`
const Info= styled.div`
position: absolute;
top:0;
left:0;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`

const Title= styled.h1`
color:black`

const Desc= styled.span`
color:black;
width:90%;
text-align:center;
font-weight: 600px;
`

const Button= styled(Link)`
border: none;
padding: 10px;
cursor: pointer;
background-color: white;
color: teal;
font-weight: 600;
text-decoration: none;
margin-top: 20px;
`

const CategoryItem = ({item}) => {
  return (
    <Container>
        <Image src={item.img}/>
        <Info>
            <Title>{item.title}</Title>
            <Desc>{item.desc}</Desc>
            <Button to="/products">SHOP NOW</Button>
        </Info>

    </Container>
  )
}

export default CategoryItem