import React from 'react'
import styled from 'styled-components'
import { useParams, Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import {mobile} from "../Responsive"


const Container= styled.div`
    display: flex;
align-items: center;
justify-content: center;
`
const Wrapper= styled.div`
width: 40%;
${mobile({width:"80%"})}
`

const Title= styled.h1``

const Form= styled.form`
display: flex;
flex-direction: column;

`
const Info= styled.span`
color: red;
margin: 10px  0px;
`

const Input= styled.input`
margin: 10px;
min-width: 40%;
padding: 10px;
margin: 10px  0px;
`

const Button= styled.button`
width: 100px;
border: none;
padding: 15px 20px;
background-color: ${props=>props.disabled===false && "teal"};;
color: white;
cursor: pointer;

`



const Checkout = () => {
    const location=useLocation()
    const [amount]=useState(location.state?location.state.amount:"") 
    const [phoneNumber, setphoneNumber]=useState(location.state?location.state.phone:"254") 
    const [cart]=useState(location.state?location.state.cart:"") 
    

    const stkPush=(phoneNumber, amount, cart)=>{
        console.log(cart)
        axios.post('https://wowkids.ddns.net/wowkids/Mpesa/StkPush', {
            phoneNumber: phoneNumber,
            customer: localStorage.getItem('visitor'),
            amount:String(amount),
            cartItems:cart
            })
            .then(function (response) {
            console.log(response);
            return(
                <h1>error.response</h1>
            )
            })
            .catch(function (error) {
            console.log(error)
            console.log(phoneNumber)
            console.log(amount)
            console.log(cart)
            console.log(localStorage.getItem('visitor'))
            alert(cart.length);
            console.log(phoneNumber)
        });
        
    }
  return (
    <Container>
        <Wrapper>
        <Title>CONFIRM DETAILS AND CHECK OUT</Title>
            <Form>
            {phoneNumber?phoneNumber.length!=12 &&( 
            <Info>Please enter correct phone number starting with country code</Info>
            ):""}
               
                <Input value={phoneNumber} onChange={(event)=>{setphoneNumber(event.target.value)}}/>
                <Input value={amount}/>
                
                <Button disabled={phoneNumber?phoneNumber.length==12?false:true:true} onClick={()=>stkPush(phoneNumber, amount, cart)}>CHECKOUT</Button>
            </Form>
        </Wrapper>
    </Container>
  )
}

export default Checkout