export const sliderItems=[
    {
        id:1,
        img:"assets/imgs/fourwheelcarblue.jpeg",
        title:"TOY CARS",
        desc:"Get a variety of woooden cars. Four wheellers, six wheelers, and many more",
        bg:"fsfafd"
    },
    {
        id:2,
        img:"assets/imgs/stackblocks.jpeg",
        title:"BLOCKS",
        desc:"Our products are intuitive, educational, safe and environment friendly.",
        bg:"fcf1ed"
    },
    {
        id:3,
        img:"assets/imgs/Tagram.jpeg",
        title:"TANGRAM",
        desc:"We help you to keep your child creatively occupied, engaged and constantly learning.",
        bg:"fsfafd"
    }
];

export const categories=[
    {
        id:1,
        img:"assets/imgs/fourwheelcarblue.jpeg",
        desc:"High quality and safe toys for your children",
        title:"TOY CARS"
    },
    {
        id:2,
        img:"assets/imgs/stackblocks.jpeg",
        desc:"Our products are intuitive, educational, safe and environment friendly.",
        title:"BLOCKS"
    },
    {
        id:3,
        img:"assets/imgs/Tagramb.jpeg",
        desc:"We help you to keep your child creatively occupied, engaged and constantly learning.",
        title:"TANGRAM"
    }
];

export const popularProducts=[
    {
        id: 1,
        img:"assets/imgs/image6.jpeg"
    },
    {
        id: 2,
        img:"assets/imgs/image7.jpeg"
    },
    {
        id: 3,
        img:"assets/imgs/image8.jpeg"
    }, 
    {
        id: 4,
        img:"assets/imgs/image9.jpeg"
    },
    {
        id: 5,
        img:"assets/imgs/image2.jpeg"
    }, 
    {
        id: 6,
        img:"assets/imgs/image1.jpeg"
    }, 
    {
        id: 7,
        img:"assets/imgs/image4.jpeg"
    },
    {
        id: 8,
        img:"assets/imgs/image10.jpeg"
    }
]