import React from 'react'
import { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import useFetch from '../useFetch';
const Container= styled.div`
    
`
const Wrapper= styled.div`

    border: 1px solid black;
    display: flex;
    flex-direction: row;
`
const Form = styled.form`
   width: 100%;
   justify-content: center;
    align-items: center;
   display: flex;
    flex-direction: column;
`;
const TextField= styled.input`
width: 50%;
height: 40px;
margin: 5px;
`
const DropDown= styled(Select)`
width: 50%;
height: 40px;
margin: 5px;
`

const CustomButton= styled.button`
width: 50%;
height: 40px;`




const AddProduct = () => {
    const [email, setEmail]=useState()
    const [cost, setCost]=useState()
    const [categories, setCategories]=useState([])
    const {data: categoriesData, loading:loadingcategories, error: getcategoryerror}= useFetch("setup/getCategories")
    const submitForm=()=>{}
    const options = []
categoriesData.map((item)=>{options.push({value: item.categoryId, label: item.name})})

    const selectedOptions=(e)=>{
      
    setCategories(Array.isArray(e)?e.map(item=>item.value):[]
        )

        //this.props.someCallback(value);
        
    }
    console.log(categories)
  return (
    <Container>
        <Wrapper>
        <Form
        onSubmit={e => {
            console.log(categories)
            e.preventDefault();
            submitForm(email);
        }}>
        <TextField
        placeholder='Item Name'
            value={email}
            onChange={(event)=>setEmail(event.target.value)}
        />
        <TextField
        placeholder='Item Cost'
            value={cost}
            onChange={(event)=>setCost(event.target.value)}
        />
        <TextField
        placeholder='Start Age'
            value={cost}
            onChange={(event)=>setCost(event.target.value)}
        />
        <TextField
        placeholder='End Age'
            value={cost}
            onChange={(event)=>setCost(event.target.value)}
        />
       <DropDown closeMenuOnSelect={false} isMulti options={options} onChange={(event)=>selectedOptions(event)}/>
        <CustomButton
            //disabled={!isValid || !email}
            >Add Product
        </CustomButton>
        </Form>
        </Wrapper>
    
        
    </Container>
  )
}

export default AddProduct