import { Add, Remove } from '@material-ui/icons'
import useFetch from '../useFetch'
import styled from 'styled-components'
import Announcement from '../components/Announcement'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Newsletter from '../components/Newsletter'
import { mobile } from '../Responsive'
import {useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'

const Container= styled.div``

const Wrapper= styled.div`
padding: 50px;
display: flex;
${mobile({padding:"10px", flexDirection: "column"})}
`

const ImageContainer= styled.div`
flex:1;`

const Image= styled.img`
width: 100%;
height: 90vh;
object-fit: cover;
${mobile({height:"40vh"})}
`

const InfoContainer= styled.div`
flex:1;
padding: 0px 50px;
${mobile({padding:"10px"})}`

const Title= styled.h1`
font-weight: 200;`

const Desc= styled.p`
margin: 20px 0px;`

const Price= styled.span`
font-weight: 100;
font-size: 40px;`

const FilterContainer= styled.div`
width: 70%;
margin: 30px 0px;
display: flex;
justify-content: space-between;
${mobile({width:"100%"})}
`

const Filter= styled.div`
display: flex;
align-items: center;`

const FilterTitle= styled.div`
font-size: 20px;
font-weight: 200;`

const FilterColor= styled.div`
width: 20px;
height: 20px;
border-radius:50%;
background-color: ${props=>props.color};
margin: 0px 5px;
cursor: pointer;`

const FilterSize= styled.div`
margin-left: 10px;
padding: 5px;`

const FilterSizeOption= styled.span`
border: 1px solid gray;
padding: 0px 10px;
`

const AddContainer=styled.div`
width: 70%;
display: flex;
align-items: center;
justify-content: space-between;
${mobile({width:"100%"})}`

const AmountContainer= styled.div`
display: flex;
align-items: center;
font-weight: 700;
cursor: pointer`

const Amount = styled.div`
height: 30px;
width:30px;
border-radius: 10px;
border: 1px solid teal;
display: flex;
align-items: center;
justify-content: center;
margin: 0px 5px;
`

const Button= styled.button`
padding: 15px;
border: 2px solid teal;
background-color: white;
cursor: pointer;
font-weight: 500;
&:hover{
    background-color: #f8f4f4;
} `

const Product = () => {
    const navigate=useNavigate()
    const {productId}=useParams();
    const [amount, setAmount]=useState(1);
    //get the product that was clicked

    const {data, loading, error}= useFetch("Products/getProduct/"+productId)
    //get customerId
  return (
    <Container>
        <Wrapper>
            <ImageContainer>
                <Image src= {data?.imgUrl}/>
            </ImageContainer>
            <InfoContainer>
                <Title>{data?.name}</Title>
                <Desc>Beautiful planter to make your working space look better</Desc>
                <Price>KES {data?.cost}</Price>
                <FilterContainer>
                    <Filter>
                        <FilterTitle>Color</FilterTitle>
                        <FilterColor color="black"/>
                        <FilterColor color="red"/>
                        <FilterColor color="blue"/>
                    </Filter>
                    <Filter>
                        <FilterTitle>For Ages</FilterTitle>
                        <FilterSize>
                            <FilterSizeOption>{data?.startAge} - {data?.endAge}</FilterSizeOption>
                        </FilterSize>
                    </Filter>
                </FilterContainer>
                <AddContainer>
                    <AmountContainer>
                        <Remove onClick={()=>(setAmount(amount<=1? amount: amount-1))}/>
                        <Amount>{amount}</Amount>
                        <Add onClick={()=>(setAmount(amount+1))}/>
                    </AmountContainer>
                    <Button onClick={()=>{navigate("/cart/"+data?.productId +"/"+amount)}}>ADD TO CART</Button>
                </AddContainer>
            </InfoContainer>
        </Wrapper>
    </Container>
  )
}

export default Product