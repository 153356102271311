import React from 'react'
import { categories } from '../data'
import CategoryItem from './CategoryItem'
import styled from 'styled-components'
import {mobile} from '../Responsive'

const Container= styled.div`
display: none;

padding: 20px;

${mobile({display: "flex",padding:"0px", flexDirection: "column"})}`
const Categories = () => {
    {console.log(categories)}
  return (
    
    <Container>
        {categories.map((item)=>(
            <CategoryItem item={item} key={item.id}/>
        ))}
    </Container>
  )
}

export default Categories