import React from 'react'

import styled from 'styled-components'
import Navbar from '../components/Navbar'
import Newsletter from '../components/Newsletter'
import Footer from '../components/Footer'

import { Outlet } from 'react-router-dom'

const Container= styled.div``
const SharedLayout = () => {
  return (
    <Container>
        <Navbar></Navbar>
        <Outlet/>
        <Newsletter></Newsletter>
        <Footer></Footer>
    </Container>
  )
}

export default SharedLayout